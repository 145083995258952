import "./App.css";

import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Products } from "./containers/Products";
import { Home } from "./containers/Home";
import { Terms } from "./containers/Terms";
import { Footer } from "./components/Footer";
import { SpeedInsights } from "@vercel/speed-insights/react";

import Loading from "./components/Loading";

function App() {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		// Simuliere Datenladen nach 3 Sekunden
		const timer = setTimeout(() => {
			setLoading(false);
		}, 3000);

		// Timer löschen, wenn die Komponente entladen wird
		return () => clearTimeout(timer);
	}, []);

	return (
		<BrowserRouter>
			{loading ? (
				<Loading />
			) : (
				<div className="App">
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/products" element={<Products />} />
						<Route path="/terms-&-services" element={<Terms />} />
					</Routes>
					<Footer />
				</div>
			)}
			<SpeedInsights />
		</BrowserRouter>
	);
}

export default App;
