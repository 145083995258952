import React from "react";
import "./style.css";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import { Data, people } from "../components/Data/data";
import { productData } from "../components/Data/productdata";
import { Navbar } from "../components/navbar";
import StatSection from "../components/stats";

export const Home = () => {
	const leafsrc = "https://cdn.discordapp.com/attachments/1080886691067338815/1197822097179824208/D7cbSrMc6kfVAAAAAElFTkSuQmCC.png?ex=65bca9c2&is=65aa34c2&hm=adbed576d0361df7ad34fda552ccb4ae717525a8c4c8731ea1eaec0c2958ea11&";

	const shuffledProducts = productData.sort(() => Math.random() - 0.5);
	const selectedProducts = shuffledProducts.slice(0, 3);
	return (
		<div id="Home">
			<div className="firstlay">
				<div className="waves"></div>
				<Navbar />
				<div id="content">
					<h1>
						WELCOME TO <br /> <span>NightSTUDIOS</span>
					</h1>
					<p>Here you will find quality scripts as well as vehicles and EUP clothing.</p>
					<div className="buttons">
						<ScrollLink to="About" smooth={true} activeClass="active" spy={true} offset={-75} duration={500}>
							{" "}
							ABOUT US <FontAwesomeIcon icon={faAngleDown} />{" "}
						</ScrollLink>
						<RouterLink to="/products">Products</RouterLink>
					</div>
				</div>
			</div>
			<div className="secondlay">
				<div className="cards">
					{Data.map((card, index) => (
						<div className="card" key={index}>
							<div className="icon">
								<img src={leafsrc} alt="cardimg" />
							</div>
							<div className="contento">
								<h1>{card.title}</h1>
								<p>{card.desc}</p>
							</div>
						</div>
					))}
				</div>
			</div>
			<div className="thirdlay" id="About">
				<h1>ABOUT US</h1>
				<p>Hello, we are Night Studios! We specialize in creating high-quality FiveM resources for your server. Our dedicated team is constantly working to develop innovative and engaging content to enrich your gaming experience. With our passion for gaming and our extensive expertise in the FiveM platform, we strive to give your server a unique and immersive atmosphere. Discover how our creative solutions can help take your FiveM experience to the next level. Welcome to Night Studios - where quality meets innovation!</p>
				<div className="container">
					{people.map((people) => (
						<div className="peoplecards">
							<div className="pplimg">
								<img src={people.img} alt="peopleimg" />
							</div>
							<h1>{people.title}</h1>
							<p>{people.designation}</p>
						</div>
					))}
					{/* <div className="peoplecards">
						<div className="wanted">
							<h1>WANTED</h1>
							<p>Backend Developer</p>
							<div className="tadg">
								<a href="mailto:austria.network@outlook.com " title="Hello Night Studios">
									Apply Now
									<FontAwesomeIcon icon={faAngleDown} />
								</a>
							</div>
						</div>
					</div> */}
				</div>
			</div>
			<StatSection />
			{/* <div className="fourthlay">
				<img src="https://cdn.discordapp.com/attachments/919139461751767040/1199091286666395800/logoweis-tran.png?ex=65c147c8&is=65aed2c8&hm=430220a9849014eba36d6424a96fe869b2f2f7b08518b050a0aec07bba5c52ef&" alt="Night" />
				<p>At Night Studios, we not only focus on quality, but also on impressive statistics that reflect the engagement of our community. We are proud to share some of our impressive metrics:</p>
				<div className="liontent">
					{statData.map((stat) => (
						<div className="stat">
							<h2>{stat.amount}</h2>
							<p>{stat.word}</p>
						</div>
					))}
				</div>
			</div> */}
			<div className="fifthlay" id="products">
				<h1>PRODUCTS</h1>
				<p>Experience gaming on a new level with the high-quality FiveM products from Night Studios. Our realistic vehicle models, dynamic scripts and user-friendly integration set new standards in terms of detail and game depth. Regular updates keep you up to date. Discover the innovation in every product!</p>
				<div className="randomProducts">
					{selectedProducts.map((product, index) => (
						<div className="randompdt" key={index}>
							<div className="pdtimg">
								<div className="category">
									<p>{product.category}</p>
								</div>
								<img src={product.images} alt={product.productName} />
							</div>
							<h2>{product.productName}</h2>
							<p>
								{product.price}
								<span>{product.decimal}</span>
							</p>
						</div>
					))}
				</div>
				<RouterLink to="/products">Want to See More ?</RouterLink>
			</div>
		</div>
	);
};
