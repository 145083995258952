import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.css";
import "../App.css";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Navbar = () => {
	const location = useLocation();
	const [isMenuOpen, setMenuOpen] = useState(false);

	const links = [
		{ to: "/", label: "Home" },
		{ to: "/products", label: "Products" },
		{ to: "/terms-&-services", label: "Terms and Conditions" },
		{ to: "https://discord.gg/EzcNYrxvDz", label: "Discord" },
	];
	const toggleMenu = () => {
		setMenuOpen(!isMenuOpen);
		document.querySelector(".c2").classList.toggle("gayab");
	};

	return (
		<div id="navbar">
			<div className="c1">
				<h2>
					Night <br />
					Studios
				</h2>
			</div>
			<FontAwesomeIcon className="menu" icon={isMenuOpen ? faTimes : faBars} onClick={toggleMenu} />
			<div className="c2 gayab">
				{links.map((link) =>
					location.pathname !== link.to ? (
						<Link key={link.to} to={link.to}>
							{link.label}
						</Link>
					) : (
						<span key={link.to} className="current-page">
							{link.label}
						</span>
					)
				)}
			</div>
		</div>
	);
};
