import React, { useEffect, useRef, useState } from "react";
import { statData } from "./Data/data";

const CounterAnimation = ({ initialValue, duration }) => {
	const [count, setCount] = useState(0);

	useEffect(() => {
		const start = Date.now();
		const end = start + duration;

		const updateCounter = () => {
			const now = Date.now();
			const progress = Math.min(1, (now - start) / duration);
			const currentCount = Math.ceil(initialValue * progress);

			setCount(currentCount);

			if (now < end) {
				requestAnimationFrame(updateCounter);
			}
		};

		updateCounter();
	}, [initialValue, duration]);

	return <span>{count}</span>;
};

const StatSection = () => {
	const [isInView, setIsInView] = useState(false);
	const targetRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						setIsInView(true);
						observer.disconnect();
					}
				});
			},
			{ threshold: 0.5 }
		);

		if (targetRef.current) {
			observer.observe(targetRef.current);
		}

		return () => observer.disconnect();
	}, []);

	return (
		<div className={`fourthlay ${isInView ? "visible" : ""}`} ref={targetRef}>
			<div className="gradient-overlay">
				<img src="https://cdn.discordapp.com/attachments/919139461751767040/1199091286666395800/logoweis-tran.png?ex=65c147c8&is=65aed2c8&hm=430220a9849014eba36d6424a96fe869b2f2f7b08518b050a0aec07bba5c52ef&" alt="Night" />
				<p>At Night Studios, we not only focus on quality, but also on impressive statistics that reflect the engagement of our community. We are proud to share some of our impressive metrics:</p>
				<div className="liontent">
					{statData.map((stat, index) => (
						<div key={index} className="stat">
							<h2>{isInView && <CounterAnimation initialValue={stat.amount} duration={1000} />}</h2>
							<p>{stat.word}</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default StatSection;
