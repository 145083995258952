export const productData = [
	{
		category: "MLOS",
		productName: "Vienna Vinewood Sign",
		price: "0€",
		decimal: "",
		description: "",
		images: "https://cdn.discordapp.com/attachments/1200101041526554626/1202233844171022346/image.png?ex=65ccb684&is=65ba4184&hm=af7c923afc897492d8a50322746ec63d6688a7310b8a89c0dc4c4834ef3ab45d&",
	},
	{
		category: "Scripts",
		productName: "Radar/Blitzer System",
		price: "11",
		decimal: ".30€",
		description: "",
		images: "https://dunb17ur4ymx4.cloudfront.net/packages/images/a6abd06fa9b454989c9f82c159ecdd13a20e4f9e.png",
	},
	// {
	// 	category: "Cars",
	// 	productName: "VW Caddy 2022 LPD Vienna",
	// 	price: "5",
	// 	decimal: ".40€",
	// 	description: "",
	// 	images: "https://media.discordapp.net/attachments/1164922459645038673/1193735770125652111/image.png?ex=65b70692&is=65a49192&hm=936c58e128cdd30176f44faea461c835eaba5d32a825b761c9d7db6c94a253fd&=&format=webp&quality=lossless&width=1362&height=671",
	// },
	// {
	// 	category: "prop",
	// 	productName: "Combination bag",
	// 	price: "Soon",
	// 	decimal: "",
	// 	description: "This resource comprises a combination bag equipped with a warning triangle, warning vest, and a first aid kit.",
	// 	images: "https://media.discordapp.net/attachments/1198570108063338536/1199094223518044251/nightstudios_warndreieck.png?ex=65c14a84&is=65aed584&hm=71868544e1c4984cab7901d7c15f0a5c1f144f81d3f777165f96c73568d2e35d&=&format=webp&quality=lossless&width=1192&height=671",
	// },
];
