export const termData = [
	{
		title: " General Conduct",
		para: "Users are expected to foster a positive and respectful environment on our platform. Any form of disrespectful or discourteous behavior towards others is not tolerated, and violators may face account consequences.",
		tags: "conduct",
	},
	{
		title: " Refund Policy",
		para: "Our no-refund policy emphasizes that all transactions are final. Users are encouraged to thoroughly review their purchases before completing transactions, as refunds will not be processed under any circumstances.",
		tags: "service",
	},
	{
		title: "Account Responsibility",
		para: "Users bear the responsibility of ensuring the security and confidentiality of their account information. Safeguarding login credentials is crucial to prevent unauthorized access and potential misuse of their accounts.",
		tags: "rules",
	},
	{
		title: " Content Usage",
		para: "Users commit to not misusing, modifying, or distributing any content from our website without explicit permission. Unauthorized usage may lead to account termination, highlighting the importance of respecting intellectual property and usage rights.",
		tags: "rules",
	},
	{
		title: " Product Information",
		para: "Product details, including descriptions and prices, are subject to change without prior notice. Users should regularly check for updates to stay informed about any modifications to product offerings or pricing.",
		tags: "conduct",
	},
	{
		title: " Privacy Policy",
		para: "We handle user data and personal information in strict accordance with our privacy policy. Users can trust that their privacy is a top priority, and we are committed to maintaining the confidentiality and security of their personal information.",
		tags: "service",
	},
	{
		title: " Termination of Service",
		para: "We retain the right to terminate or suspend user accounts in the event of any violation of our terms of service. This measure ensures a safe and compliant environment for all users, promoting adherence to established guidelines.",
		tags: "service",
	},
	{
		title: " Disclaimer",
		para: "Users are advised that we are not liable for any damages, losses, or liabilities incurred while using our products or services. This disclaimer clarifies that users engage with our offerings at their own risk and discretion.",
		tags: "conduct",
	},
	{
		title: " Governing Law",
		para: "These terms and conditions are governed by the laws of [Your Jurisdiction]. In the event of any disputes, users acknowledge that resolutions will be sought in the appropriate courts, ensuring legal clarity and jurisdiction.",
		tags: "rules",
	},
];
