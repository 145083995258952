export const Data = [
	{
		title: "Fast Delivery",
		desc: "Get instant delivery within 2-3 days",
	},
	{
		title: "24/7 Discord Support",
		desc: "Our team is available to assist you around the clock",
	},
	{
		title: "Guaranteed Satisfaction",
		desc: "Our products are guaranteed to satisfy you",
	},
	{
		title: "Quality Products",
		desc: "Discover high-quality products with fast delivery",
	},
];

export const people = [
	{
		img: "https://cdn.discordapp.com/attachments/919139461751767040/1199387306138804305/trevor.png?ex=65c25b79&is=65afe679&hm=30f561d5d39ef50aa9a12dcd11147cad0ec36f7f0642462ff8575f440b8cd256&",
		title: "Alan",
		designation: "Managment, 3D Modeler",
	},
	{
		img: "https://cdn.discordapp.com/attachments/919139461751767040/1199386891536060667/michael.png?ex=65c25b16&is=65afe616&hm=a7642dcb1a4712b2f2a235be48f2564a850711d5676e6772c5d38cf0af420f7d&",
		title: "Niklas",
		designation: "Managment, Web Developer, Script Developer, (Allrounder)",
	},
	{
		img: "https://cdn.discordapp.com/attachments/1080886691067338815/1197823165309661204/0NXWlLq.png?ex=65bcaac1&is=65aa35c1&hm=681c5080785c52ff552c1292b409c4f251449f879f8b8c227a90c564e5bd6b92&",
		title: "Max",
		designation: "Staff, 3D Modeler",
	},
];
export const statData = [
	{ amount: 2, word: "Products" },
	{ amount: 0, word: "Customers" },
	{ amount: 0, word: "Reviews" },
];
