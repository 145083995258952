import React, { useEffect, useState } from "react";
import "./style.css";
import "../App.css";
import { Navbar } from "../components/navbar";
import { termData } from "../components/Data/Terms";

export const Terms = () => {
	const [selectedCategory, setSelectedCategory] = useState("service");
	const Hat = [{ cat: "ALL" }, { cat: "service" }, { cat: "rules" }, { cat: "conduct" }];

	const filteredTerms = selectedCategory === "ALL" ? termData : termData.filter((p) => p.tags === selectedCategory);

	const handleCategoryClick = (category) => {
		setSelectedCategory(category);
	};
	useEffect(() => {
		document.title = "Night Studios | Terms and Conditions";
	});
	return (
		<div className="termslay">
			<Navbar />
			<div id="Title">
				<h1>Terms and Conditions</h1>
				<p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Reiciendis tempore ex harum aliquam ipsa sit vero rerum illo nobis quaerat at adipisci, placeat delectus obcaecati distinctio asperiores magni quidem maiores?</p>
			</div>
			<div className="cattags">
				{Hat.map((t) => (
					<button key={t.cat} className={selectedCategory === t.cat ? "active" : ""} onClick={() => handleCategoryClick(t.cat)}>
						{t.cat}
					</button>
				))}
			</div>
			<div id="terms">
				{filteredTerms.map((term, index) => (
					<div className="terms" key={index}>
						<h1>{term.title}</h1>
						<p>{term.para}</p>
					</div>
				))}
			</div>
		</div>
	);
};
