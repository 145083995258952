import React, { useEffect, useState } from "react";
import "./style.css";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Navbar } from "../components/navbar";
import { productData } from "../components/Data/productdata";

export const Products = () => {
	const [selectedCategory, setSelectedCategory] = useState("prop");
	const [visibleCardIndex, setVisibleCardIndex] = useState(null);
	useEffect(() => {
		document.title = "Night Studios | Products";
	});

	const Hat = [{ cat: "ALL" }, { cat: "prop" }, { cat: "MLOS" }, { cat: "EUP" }, { cat: "Cars" }, { cat: "Scripts" }];

	const filteredProducts = selectedCategory === "ALL" ? productData : productData.filter((p) => p.category === selectedCategory);

	const handleCategoryClick = (category) => {
		setSelectedCategory(category);
		setVisibleCardIndex(null);
	};

	const showDet = (index) => {
		setVisibleCardIndex(index);
	};

	const handleClick = () => {
		setVisibleCardIndex(null);
	};

	return (
		<div className="pdtlay">
			<Navbar />
			<div id="contained">
				<div className="cattags">
					{Hat.map((t) => (
						<button key={t.cat} className={selectedCategory === t.cat ? "active" : ""} onClick={() => handleCategoryClick(t.cat)}>
							{t.cat}
						</button>
					))}
				</div>
				<div className="pcards">
					{filteredProducts.map((p, index) => (
						<div key={index} className={`pcard ${visibleCardIndex === index ? "visible" : ""}`} style={{ order: visibleCardIndex === index ? -1 : "1" }}>
							<div className="pdtimg smth" onClick={() => showDet(index)}>
								<div className="category">
									<p>{p.category}</p>
								</div>
								<img src={p.images} alt={p.productName} />
								<div className="det">
									<h4>{p.productName}</h4>
									<p>{p.price + p.decimal}</p>
									<button onClick={() => showDet(index)}>See Details</button>
								</div>
							</div>
							<div className={`desc ${visibleCardIndex === index ? "visible" : ""}`}>
								<h1>{p.productName}</h1>
								<FontAwesomeIcon icon={faTimes} onClick={handleClick} />
								<p className="cat">Category | {p.category}</p>
								<p className="descs">{p.description}</p>
								<a href="https://store.nightstudios.eu/">
									Buy Now <span>{p.price + p.decimal}</span>
								</a>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
