// eslint-disable-next-line
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// eslint-disable-next-line
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import React from "react";
import "../App.css";
import "./style.css";
import { Link } from "react-router-dom";

export const Footer = () => {
	return (
		<div id="Footer">
			<div className="horitext">
				<div className="logo">
					<img src="https://cdn.discordapp.com/attachments/919139461751767040/1199091286666395800/logoweis-tran.png?ex=65c147c8&is=65aed2c8&hm=430220a9849014eba36d6424a96fe869b2f2f7b08518b050a0aec07bba5c52ef&" alt="Night" />
					<div className="fotext">
						<h1>
							Night <br /> Studios
						</h1>
					</div>
				</div>
				<p>Your Contact for GTA V Modifications. We specialize in MLOs, Props, EUP, Scripts, and Cars. Our commitment to quality is unwavering, and we strive to exceed customer satisfaction.</p>
			</div>
			<div className="links">
				<ul>
					<Link to="/">Home</Link>
					<Link to="/products">Products</Link>
					<Link to="/terms-&-services">Terms of Service</Link>
				</ul>
			</div>
			<div className="follow">
				<p style={{ whiteSpace: "nowrap", margin: "1em 0", width: "100%" }}>Email : austria.network@outlook.com</p>
				<div className="us">
					<h3 style={{ margin: "0", fontFamily: "Akira", color: "white" }}>Follow us</h3>
					<div className="sl">
						<a href="https://www.tiktok.com/@nightstudios.official">
							<FontAwesomeIcon icon={faTiktok} />
						</a>
						{/* <a href="https://tiktok.com">
							<FontAwesomeIcon icon={faTiktok} />
						</a>
						<a href="https://tiktok.com">
							<FontAwesomeIcon icon={faTiktok} />
						</a> */}
						{/* Update this too */}
					</div>
				</div>
			</div>
			<p className="copy">All Rights Reserved 2024 - Night Studios™</p>
		</div>
	);
};
